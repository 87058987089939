import FingerprintJS from '@fingerprintjs/fingerprintjs'

export async function fingerprint(): Promise<string | null> {
  var fingerprint = null
  try {
    const fp = await FingerprintJS.load()

    return (await fp.get()).visitorId
  } catch(error) {
    captureException(new Error('Failed to get user fingerprint', { cause: error }))

    return null
  }
}