export interface FacebookPixelAttribution {
  fbp: string | null,
  fbc: string | null
}

export default {
  getPixelAttribution(): FacebookPixelAttribution {
    const fbp = useCookie("_fbp")
    const fbc = useCookie("_fbc")

    return {
      fbp: fbp.value ?? null,
      fbc: fbc.value ?? null,
    }
  }
};